import {Button,  Modal, Table} from "semantic-ui-react";
import {useState} from "react";



export default function Guestlist(props: {
    guests: [{name: string, prename: string}],
    open?: boolean
}){

    const [open,setOpen] = useState(false)

    const CloseGuestList =
        <Button onClick={()=>{setOpen(false);}}>
            Schließen
        </Button>

    const OpenGuestList = props.guests?.length>0 &&
        <Button onClick={()=>{setOpen(true);}}>
            Gästeliste
        </Button>


    const GuestTable = props.guests?.length>0 &&
        <Modal open={open} onActionClick={()=>{setOpen(true)}} >
            <Modal.Content>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Zugesagt haben ebenfalls: ({props.guests.length})</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {props.guests.map((e,i)=>{
                            return  (
                                <Table.Row>
                                    <Table.Cell>
                                        {e.prename+" "+e.name?.at(0)?.toString()+". "}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                {CloseGuestList}
            </Modal.Actions>
        </Modal>

    return(
        <>
            {OpenGuestList}
            {GuestTable}
        </>
    )
}
