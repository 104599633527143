import InviteWarning from "./InviteWarning";

export default function InviteInfos(props: {warnings: [{
    text: string
    }]}){

    return(

        <>
            {props.warnings.map((e,i) => {
                return <InviteWarning warning={e.text} key={i}/>
            })}
        </>
    )
}