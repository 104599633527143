import React from 'react';
import {Message} from "semantic-ui-react";


export default function InvitationError(props: {title?: string, message: string}) {
    return (

                <Message>
                    <Message.Header>{props.title || "Information"}</Message.Header>
                    <p>
                        {props.message || ""}
                    </p>
                </Message>
    );
}
