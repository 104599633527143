import React, {useState} from "react";
import {Button, Form} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
export default function ObtainInvite(){

    let navigate = useNavigate();

    const [token,setToken] = useState("")

    return(
        <Form size='large'>
            <Form.Input fluid icon='calendar' iconPosition='left' placeholder='token' value={token} onChange={(e)=> setToken(e.target.value)}/>
            <Button color='black' fluid size='large' onClick={()=>{
                navigate("/inv/"+token);
            }}>
                Abrufen
            </Button>
        </Form>
    )
}