import React from 'react';
import {Grid, Header} from "semantic-ui-react";
import ObtainInvite from "./ObtainInvite";


export default function Startpage() {
    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 600 }}>
                <Header as='h2' color='black' textAlign='center'>
                    Einladung abrufen
                </Header>
                <ObtainInvite/>
            </Grid.Column>
        </Grid>
    );
}
