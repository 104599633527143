import InviteText from "./InviteText";

export default function InviteTexts(props: {texts: [{
    text: string
    }]}){

    return(

        <>
            {props.texts.map((e,i) => {
                return <InviteText text={e.text} key={i}/>
            })}
        </>
    )
}