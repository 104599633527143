import InviteInfo from "./InviteInfo";

export default function InviteInfos(props: {infos: [{
    text: string
    }]}){

    return(

        <>
            {props.infos.map((e,i) => {
                return <InviteInfo info={e.text} key={i}/>
            })}
        </>
    )
}