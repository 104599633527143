import {Button, Icon} from "semantic-ui-react";
import {faSmile,faSadCry} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {gql, useMutation} from "@apollo/client";
import {useParams} from "react-router-dom";

export default function InviteParticipation(props: {
    participation?: string
}){


    let params = useParams();

    const Undecided = (!props.participation && true) || false;
    const Accepted = props.participation === 'ACCEPTED' || false;
    const Declined = props.participation === 'DECLINED' || false;


    const acceptInvite = gql`
        mutation Mutation($token: ID!) {
            acceptInvite(token: $token) {
                participation,
                attendee {
                    name,prename
                }
                event {
                    title,
                    texts {
                        text
                    },
                    guests {
                        prename,name
                    }
                }
            }
        }
    `

    const declineInvite = gql`
        mutation Mutation($token: ID!) {
            declineInvite(token: $token) {
                participation,
                attendee {
                    name,prename
                }
                event {
                    title,
                    texts {
                        text
                    },
                    guests {
                        prename,name
                    }
                }
            }
        }
    `

    const [acceptInvitation] = useMutation(acceptInvite,{
        variables: {
            token: params.token || ""
        }
    })

    const [declineInvitation] = useMutation(declineInvite,{
        variables: {
            token: params.token || ""
        }
    })

    const SmileyOK = Accepted &&
    <Icon size={"large"}>
        <FontAwesomeIcon icon={faSmile} />
    </Icon>

    const SmileyMeh = Declined &&
    <Icon size={"large"}>
        <FontAwesomeIcon icon={faSadCry} />
    </Icon>

    // const HasNotYetDecied = !(props.participation) &&
    //     <Button.Group>
    //         <Button size='big' positive> Teilnehmen</Button>
    //         <Button.Or  size='big' text={''} />
    //         <Button size='big' negative>Absagen</Button>
    //     </Button.Group>
    const HasDecided =
        <Button.Group>
            <Button onClick={()=>{
                acceptInvitation().then(()=> window.location.reload());
                // window.location.reload();
            }} size='big' positive={Undecided || Accepted}> {(Accepted && "Zugesagt") || "Teilnehmen"} {SmileyOK}</Button>
            {/*<Button.Or  size='big' text={''} />*/}
            <Button onClick={()=>{
                declineInvitation().then(()=> window.location.reload());
                // window.location.reload();
            }} size='big' negative={Undecided || Declined}>{(Declined && "Abgesagt" ) || "Absagen"} {SmileyMeh}</Button>
        </Button.Group>
    return(
        <>
            {HasDecided}
        </>
    )
}