import React, {useEffect} from 'react';
import {Grid} from "semantic-ui-react";
import {gql, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import InviteHeader from "./InviteHeader";
import InviteInfos from "./InviteInfos";
import InviteTexts from "./InviteTexts";
import InviteWarnings from "./InviteWarnings";
import InviteParticipation from "./InviteParticipation";
import InvitationError from "./InvitationError";
import ObtainInvite from "../Startpage/ObtainInvite";
import Guestlist from "./Guestlist/Guestlist";
export default function Invitation() {

    let params = useParams();

    const getInvite = gql`
        query getInvites($token: ID!) {
            Invite(token: $token) {
                participation,
                attendee {
                    name,prename
                }
                event {
                    title,
                    warnings {
                        text
                    },
                    texts {
                        text
                    },
                    guests {
                        prename,name
                    },
                    infos {
                        text
                    }
                }
            }
        }
    `


    const {loading,error,data} = useQuery(getInvite,{
        variables: {
            token: params.token || ""
        }
    });


    const Loading = (loading) && <InvitationError title={"Abrufen...."} message={"Daten werden geladen. Bitte warten."}/>;
    const Error = (error) && <InvitationError title={"404. Keine Einladung"} message={"Leider gibt es für diesen Token wohl keine Einladung."}/>
    const NoInvite = (!(data?.Invite)) &&
        <>
            <InvitationError title={"404. Keine Einladung"} message={"Leider gibt es für diesen Token wohl keine Einladung."}/>
            <ObtainInvite/>
        </>

    const Empty = (Loading || Error || NoInvite) &&
    <>
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 600 }}>
                {Loading || Error || NoInvite}
            </Grid.Column>
        </Grid>

    </>

    useEffect(()=>{
        (data?.Invite?.event.title || false) && (document.title = data.Invite.event.title);
    },[data])

    const Invite =data?.Invite && <>
    <Grid textAlign='center' style={{ height: '100vh', marginTop: '5em' }} verticalAlign='top'>
        <Grid.Column style={{ maxWidth: 800 }}>
            <InviteHeader name={data.Invite.attendee.prename} />
            <InviteParticipation participation={data.Invite.participation}/>
            <InviteWarnings warnings={data.Invite.event.warnings || []} />
            <InviteTexts texts={data.Invite.event.texts || []} />
            <Guestlist guests={data.Invite.event.guests || []} />
            <InviteInfos infos={data.Invite.event.infos || []} />
            {/*<a onClick={()=>{   console.log("Accept");  acceptInvitation(); }}>Accept</a>*/}
            {/*<a onClick={()=>{   console.log("Decline");  declineInvitation(); }}>Decline</a>*/}
        </Grid.Column>
    </Grid>

    </>

    return (
        <>
            {Empty || Invite}
        </>
    );
}
