import React from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import App from './App';
// const {dev_css} = require("./dev.css");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>

    {/*{(process.env.NODE_ENV === 'development') ? dev_css : false}*/}

    <App />

  </React.StrictMode>
);
