import React from 'react';
import './App.css';
import {Container, Menu} from "semantic-ui-react";
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import Startpage from "./Startpage/Startpage";
import Invitation from "./Invitation/Invitation";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

export default function App() {
    const client = new ApolloClient({
        uri: process.env.API_URL || 'https://api-invite.norbert-ruehl.de/',
      cache: new InMemoryCache(),
    });

  return (
      <ApolloProvider client={client}>
          <BrowserRouter>
                <div className="App" >
                    <Menu fixed={'top'}>
                        <Menu.Menu position='left'>
                            <Link to={"/"}>
                                <h1>Norb Invite</h1>
                            </Link>
                        </Menu.Menu>
                    </Menu>
                    <Container  style={{ marginTop: '0em'}}>
                        <Routes>
                        <Route index element = {<Startpage/>}></Route>
                            <Route path="/inv/:token" element={<Invitation/>} />
                            <Route path="*" element = {<Startpage/>}></Route>
                        </Routes>
                    </Container>
                </div>
          </BrowserRouter>
      </ApolloProvider>
  );
}
